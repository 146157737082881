/* You can add global styles to this file, and also import other style files */
// @import '~@aws-amplify/ui-angular/theme.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import '~@aws-amplify/ui-angular/theme.css';


@mixin dialog-btn-row{
  height: 50px;
}

@mixin background($imgpath,$position:0 0,$repeat:no-repeat){
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
  }
}

.mat-tab-label{
  background-color: #515151;
}

.mat-tab-body {
  flex-grow: 1;
}

.mat-tab-label{}

#qwerty-chart {
  .mat-tab-label{
    padding: 0 5px;
    opacity: 1;
    .mat-tab-label-content{
      width: 100%;
      display:flex;
      justify-content: space-between;
      padding-left:10px;
    }
    // border-left: .5px solid #fff !important;
    // border-right: .5px solid #fff;
  }

  button.add-lot{
    padding: 0px;
    min-width:40px;
    background-color: #000000;

  }

}

.mat-checkbox-inner-container input {
  display: inline-block;
  height: 20px;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 20px;
  flex-shrink: 0;
  text-align: center;
  margin:0 auto
}

.lot-btn-container{
 @include dialog-btn-row;
 .mat-dialog-actions{margin-bottom:0px;}
}

.attachment-block .inline-attachments{
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
    ol{
      margin-top: 8px;
      padding-left: 0px;
    }
}

html, body {
  height: 100%;
}

.full-height {
  height: 100%;
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-content {
  height: calc(100vh - 64px - 64px);
  width: 100vw;
  overflow-y: scroll;
}

.app-header, .app-footer {
  height: 64px;
}

.mat-tab-body-wrapper {
  height:100%;
}

